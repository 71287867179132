/* global axios */
import ApiClient from '../ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true, wgpt: true });
  }

  addFile(campaignId, data) {
    return axios.post(`${this.url}/${campaignId}/files`, data);
  }

  getTargetContactsCount(campaignId) {
    return axios.get(`${this.url}/${campaignId}/target_contacts_count`);
  }

  start(campaignId, data) {
    return axios.post(`${this.url}/${campaignId}/start`, data);
  }

  stop(campaignId) {
    return axios.post(`${this.url}/${campaignId}/stop`);
  }

  clone(campaignId) {
    return axios.post(`${this.url}/${campaignId}/clone`);
  }
}

export default new CampaignsAPI();
