/* global axios */
import ApiClient from '../ApiClient';

class WgptBotToolsAPI extends ApiClient {
  constructor(botId) {
    super(`bots/${botId}/tools`, { accountScoped: true, wgpt: true });
  }

  addFile(toolSetId, id, data) {
    return axios.post(`${this.url}/${toolSetId}/add_file/${id}`, data);
  }

  addTool(toolSetId, data) {
    return axios.post(`${this.url}/${toolSetId}/add_tool`, data);
  }

  cloneTool(toolSetId, id) {
    return axios.post(`${this.url}/${toolSetId}/clone_tool/${id}`);
  }

  deleteTool(toolSetId, id) {
    return axios.delete(`${this.url}/${toolSetId}/delete_tool/${id}`);
  }

  updateTool(toolSetId, id, data) {
    return axios.patch(`${this.url}/${toolSetId}/update_tool/${id}`, data);
  }
}

export default WgptBotToolsAPI;
