/* global axios */
import ApiClient from '../ApiClient';

class WgptBoardListCardsAPI extends ApiClient {
  constructor(boardId, listId) {
    super(`boards/${boardId}/lists/${listId}/cards`, {
      accountScoped: true,
      wgpt: true,
    });
  }

  get({ limit, offset, filters }) {
    const filtersObj = filters ?? {};
    if (filtersObj.dates?.length) {
      filtersObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return axios.get(this.url, {
      params: { limit, offset, filters: JSON.stringify(filtersObj) },
    });
  }

  updateLabels(cardId, labels) {
    return axios.post(`${this.url}/${cardId}/labels`, { labels });
  }

  addAttachment(cardId, data) {
    return axios.post(`${this.url}/${cardId}/attachments`, data);
  }

  removeAttachment(cardId, attachmentId) {
    return axios.delete(`${this.url}/${cardId}/attachments/${attachmentId}`);
  }

  executeAutomation(cardId, automationId, parameters) {
    const options = {
      automation_id: automationId,
      parameters,
    };
    return axios.post(`${this.url}/${cardId}/execute_automation`, options);
  }
}

export default WgptBoardListCardsAPI;
