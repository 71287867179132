import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CampaignsAPI from '../../api/wgpt/campaigns';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  targetContactsCount: {},
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isFetchingTargetDetails: false,
    isStartingOrStopping: false,
    isCloning: false,
  },
};

export const getters = {
  getCampaigns: $state => $state.records,
  getUIFlags: $state => $state.uiFlags,
  getCampaign: $state => id => {
    const campaign = $state.records.find(record => record.id === Number(id));
    return campaign || {};
  },
  getTargetContactsCount: $state => $state.targetContactsCount,
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignsAPI.get();
      commit(types.SET_WGPT_CAMPAIGNS, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, campaignData) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isCreating: true });
    try {
      const response = await CampaignsAPI.create(campaignData);
      commit(types.ADD_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...updateData }) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      const response = await CampaignsAPI.update(id, updateData);
      commit(types.EDIT_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
    return null;
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isDeleting: true });
    try {
      await CampaignsAPI.delete(id);
      commit(types.DELETE_WGPT_CAMPAIGN, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await CampaignsAPI.show(id);
      commit(types.ADD_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isFetchingItem: false });
    }
    return null;
  },
  addFile: async ({ commit }, { id: campaignId, file }) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      const id = Number(campaignId);
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await CampaignsAPI.addFile(id, formData);
      const { files } = data;
      commit(types.EDIT_WGPT_CAMPAIGN, { id, files });
      return files[files.length - 1];
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  getTargetContactsCount: async ({ commit }, id) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isFetchingTargetDetails: true });
    try {
      const response = await CampaignsAPI.getTargetContactsCount(id);
      commit(types.SET_WGPT_CAMPAIGN_TARGET_DETAILS, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, {
        isFetchingTargetDetails: false,
      });
    }
  },
  start: async ({ commit }, { id, ...data }) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isStartingOrStopping: true });
    try {
      const response = await CampaignsAPI.start(id, data);
      commit(types.EDIT_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isStartingOrStopping: false });
    }
    return null;
  },
  stop: async ({ commit }, id) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isStartingOrStopping: true });
    try {
      const response = await CampaignsAPI.stop(id);
      commit(types.EDIT_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isStartingOrStopping: false });
    }
    return null;
  },
  clone: async ({ commit }, id) => {
    commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isCloning: true });
    try {
      const response = await CampaignsAPI.clone(id);
      commit(types.ADD_WGPT_CAMPAIGN, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WGPT_CAMPAIGN_UI_FLAG, { isCloning: false });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_CAMPAIGN_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_WGPT_CAMPAIGNS]: MutationHelpers.set,
  [types.ADD_WGPT_CAMPAIGN]: MutationHelpers.setSingleRecord,
  [types.EDIT_WGPT_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_WGPT_CAMPAIGN]: MutationHelpers.destroy,
  [types.REMOVE_WGPT_CAMPAIGN_FILE]($state, { id, fileId }) {
    $state.records.forEach((record, index) => {
      if (record.id === id) {
        const filesBefore = record.files || [];
        const files = filesBefore.filter(file => file.id !== fileId);
        $state.records = {
          ...$state.records,
          [index]: { ...record, files },
        };
      }
    });
  },
  [types.SET_WGPT_CAMPAIGN_TARGET_DETAILS]($state, data) {
    $state.targetContactsCount = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
