import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import WgptBotToolsAPI from '../../api/wgpt/botTools';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isCloning: false,
  },
};

export const getters = {
  getToolSets($state) {
    return $state.records;
  },
  getToolSet: $state => toolSetId => {
    const toolSet = $state.records.find(({ id }) => id === Number(toolSetId));
    return toolSet ?? {};
  },
  getTools: $state => toolSetId => {
    const toolSet = $state.records.find(({ id }) => id === Number(toolSetId));
    if (!toolSet) return [];

    const legacyTools = toolSet.config?.tools ?? [];
    const automationTools = toolSet.tools.map(tool => ({
      ...tool,
      type: 'function_automation',
    }));
    const tools = [...legacyTools, ...automationTools];
    return tools;
  },
  getTool: $state => (toolSetId, toolId) => {
    const toolSet = $state.records.find(({ id }) => id === Number(toolSetId));
    if (!toolSet) return {};

    const legacyTools = toolSet.config?.tools ?? [];
    const automationTools = toolSet.tools.map(tool => ({
      ...tool,
      type: 'function_automation',
    }));
    const tools = [...legacyTools, ...automationTools];
    return tools.find(tool => tool.id === Number(toolId)) ?? {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, botId) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isFetching: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).get();
      commit(types.SET_WGPT_BOT_TOOLS, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { botId, ...createObj }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isCreating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).create(createObj);
      commit(types.ADD_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { botId, id, ...updateObj }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).update(id, updateObj);
      commit(types.EDIT_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { botId, id }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isDeleting: true });
    try {
      await new WgptBotToolsAPI(botId).delete(id);
      commit(types.DELETE_WGPT_BOT_TOOL, id);
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { botId, id }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).show(id);
      commit(types.ADD_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isFetchingItem: false });
    }
  },
  clone: async ({ commit }, { botId, id }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isCloning: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).clone(id);
      commit(types.ADD_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isCloning: false });
    }
  },
  addTool: async ({ commit }, { botId, toolSetId, ...createObj }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).addTool(
        toolSetId,
        createObj
      );
      commit(types.EDIT_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
  updateTool: async ({ commit }, { botId, toolSetId, id, ...updateObj }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).updateTool(
        toolSetId,
        id,
        updateObj
      );
      commit(types.EDIT_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
  deleteTool: async ({ commit }, { botId, toolSetId, id }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).deleteTool(
        toolSetId,
        id
      );
      commit(types.EDIT_WGPT_BOT_TOOL, data);
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
  cloneTool: async ({ commit }, { botId, toolSetId, id }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await new WgptBotToolsAPI(botId).cloneTool(
        toolSetId,
        id
      );
      commit(types.EDIT_WGPT_BOT_TOOL, data);
      return data;
    } catch (error) {
      throw throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
  addFile: async ({ commit }, { botId, toolSetId, id, file }) => {
    commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: true });
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await new WgptBotToolsAPI(botId).addFile(
        toolSetId,
        id,
        formData
      );
      commit(types.EDIT_WGPT_BOT_TOOL, data);
      const tool = data.tools.find(({ id: toolId }) => toolId === id) ?? {};
      const files = tool.files ?? [];
      return files[files.length - 1];
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_TOOL_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_BOT_TOOL_UI_FLAG]($state, data) {
    $state.uiFlags = { ...$state.uiFlags, ...data };
  },
  [types.ADD_WGPT_BOT_TOOL]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOT_TOOLS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOT_TOOL]: MutationHelpers.update,
  [types.DELETE_WGPT_BOT_TOOL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
